<template>
  <div class="enterprise-container">
    <!-- 后退按钮 -->
    <button @click="goBack" class="back-button">返回</button>
    <!-- 当前企业信息 -->
    <div class="current-enterprise-info">
      <span>{{ currentEnterpriseName }}</span>
    </div>
    <hr style="margin: 20px 0; border: 1px solid #ccc;" />
    <!-- 当前企业下的操作按钮 -->
    <div class="action-buttons">
      <button
          v-if="showAddSubButton"
          @click="addEnterprise"
          class="action-button"
      >
        新增关联企业
      </button>
      <button
          v-if="showAddRelaButton"
          @click="addRelation"
          class="action-button"
      >
        新增关联关系
      </button>
    </div>

    <!-- 企业方框 -->
    <div
        v-for="enterprise in enterprises"
        :key="enterprise.id"
        class="enterprise-box"
        :style="{ backgroundColor: getBackgroundColor(enterprise.type) }"
    >
      <div class="enterprise-name" @click="onClickSubEnterprise(enterprise.id)">
        {{ enterprise.name }} - {{ getRegionName(enterprise.regionId) }} - {{ enterprise.address }}
      </div>
      <div class="arrow" @click="goToPageC(enterprise.id)">...</div>
    </div>

    <!-- 新增关联关系对话框 -->
    <el-dialog
        title="新增关联关系"
        :visible.sync="relationDialogVisible"
        width="80%"
    >
      <el-form>
        <el-form-item label="选择关联企业">
          <el-select
              v-model="selectedRelationEnterprise"
              placeholder="请选择关联企业"
              style="width: 100%"
          >
            <el-option
                v-for="enterprise in availableRelationEnterprises"
                :key="enterprise.id"
                :label="enterprise.name"
                :value="enterprise.id"
            >
              {{ enterprise.name }} - {{ getRegionName(enterprise.regionId) }}
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="relationDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveRelation">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { post } from '@/apis/restUtils';

export default {
  props: ['region', 'isRegion', 'enterpriseId', 'history'],
  data() {
    return {
      enterprises: [], // 当前显示的企业列表
      currentRegion: '', // 当前省份
      currentParent: '',
      currentEnterprise: '',
      currentEnterpriseName: '', // 当前企业名称
      currentRegionName: '', // 当前省份名称
      regionMap: {}, // 省份ID到名称的映射
      // 新增关联关系相关数据
      relationDialogVisible: false,
      selectedRelationEnterprise: '',
      availableRelationEnterprises: []
    };
  },
  computed: {
    // 是否显示新增关联企业按钮
    showAddSubButton() {
      // 这里添加判断逻辑，根据当前企业类型决定是否显示
      // 示例：只有特定类型的企业才显示此按钮
      return this.isRegion ||
          (this.currentEnterprise &&
              ['holding', 'subsidiary', 'group'].includes(this.currentEnterprise.type));
    },
    showAddRelaButton(){
      return this.isRegion ||
          (this.currentEnterprise &&
              ['holding', 'subsidiary'].includes(this.currentEnterprise.type));
    }
  },
  async mounted() {
    await this.fetchRegions(); // 获取省份映射
    if (this.isRegion) {
      this.currentRegion = this.region;
      this.fetchEnterprisesByRegion(this.currentRegion);
      this.currentEnterpriseName = '当前省份';
      this.currentRegionName = this.getRegionName(this.currentRegion);
    } else {
      this.currentParent = this.enterpriseId;
      await this.fetchEnterpriseInfo(this.currentParent);
      await this.fetchSubEnterprises(this.currentParent);
    }
  },
  methods: {
    // 获取所有省份的映射
    async fetchRegions() {
      await post('/wx/wxGetRegions', {}, (response) => {
        if (response && response.data && response.data.entity) {
          response.data.entity.forEach((region) => {
            this.regionMap[region.id] = region.name;
          });
        }
      });
    },
    // 根据省份ID获取省份名称
    getRegionName(regionId) {
      return this.regionMap[regionId] || '未知省份';
    },
    // 根据企业ID获取企业名称（占位逻辑）
    getEnterpriseName() {
      return this.currentEnterprise.name;
    },
    // 根据企业ID获取省份名称（占位逻辑）
    getRegionNameByEnterpriseId() {
      // 这里需要替换为实际获取企业省份ID并转义的逻辑
      return this.regionMap[this.currentEnterprise.regionId] || '未知省份'; // 示例，假设企业ID关联的regionId为'1'
    },
    // 根据省份获取企业数据
    fetchEnterprisesByRegion(regionId) {
      post(`/wx/getEnterprises`, { 'regionId': regionId }, this.fetchEnterprisesConsumer);
    },
    fetchEnterprisesConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.enterprises = response.data.entity;
      } else {
        console.error('获取企业数据失败: 响应数据格式错误');
      }
    },
    // 根据企业ID获取子公司和关联企业
    async fetchSubEnterprises(enterpriseId) {
      await post(`/wx/enterprise/getSub`, { 'parentId': enterpriseId }, this.fetchSubEnterprisesConsumer);
      await post(`/wx/enterprise/getRela`, { 'entity_id': enterpriseId }, this.fetchRelaEnterprisesConsumer);
      this.currentParent = enterpriseId;
      await this.fetchEnterpriseInfo(this.currentParent);
    },
    async fetchEnterpriseInfo(id) {
      await post(`/wx/enterprise/info`, {'id': id}, this.fetchEnterpriseInfoConsumer);
    },
    fetchEnterpriseInfoConsumer(response) {
      // 处理返回的企业信息
      if (response && response.data && response.data.entity) {
        this.currentEnterprise = response.data.entity.enterprise;
        this.currentEnterpriseName = this.getEnterpriseName();
        this.currentRegionName = this.getRegionNameByEnterpriseId();
      }
    },
    onClickSubEnterprise(enterpriseId) {
      this.history.push(this.currentParent);
      this.fetchSubEnterprises(enterpriseId);
    },
    fetchSubEnterprisesConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.enterprises = response.data.entity;
      } else {
        console.error('获取企业数据失败: 响应数据格式错误');
      }
    },
    fetchRelaEnterprisesConsumer(response) {
      if (response && response.data && response.data.entity) {
        this.enterprises = [...this.enterprises, ...response.data.entity];
      }
    },
    // 后退到上一级
    goBack() {
      if (this.history.length !== 0) {
        const previousId = this.history.pop();
        this.fetchSubEnterprises(previousId);
      } else {
        this.$emit('go-back');
      }
    },
    // 跳转到页面C
    goToPageC(enterpriseId) {
      this.history.push(this.currentParent);
      this.$emit('select-enterprise', enterpriseId);
    },
    // 根据企业类型返回背景颜色
    getBackgroundColor(type) {
      // return type === 'partner' ? '#fffbe6' : '#e6f7ff';
      switch (type){
        case 'partner':
          return '#fffbe6';
        case 'holding':
          return '#e8f8e8';
        case 'subsidiary':
          return '#e6f7ff';
        default:'#e6f7ff';
      }
    },
    addEnterprise() {
      this.history.push(this.currentParent);
      this.$emit('add-enterprise', this.currentParent);  // || this.region
    },
    // 新增关联关系方法
    async addRelation() {
      this.relationDialogVisible = true;
      this.selectedRelationEnterprise = '';
      await this.fetchAvailableRelationEnterprises();
    },

    // 获取可关联的企业列表
    async fetchAvailableRelationEnterprises() {
      try {
        await post('/wx/enterprise/getPartners', {}, (response) => {
              if (response && response.data && response.data.status === 'success') {
                this.availableRelationEnterprises = response.data.entity;
              } else {
                this.$message.error('获取可关联企业列表失败');
              }
            }
        );
      } catch (error) {
        this.$message.error('获取可关联企业列表失败');
      }
    },

    // 保存关联关系
    async saveRelation() {
      if (!this.selectedRelationEnterprise) {
        this.$message.warning('请选择关联企业');
        return;
      }

      try {
        await post('/wx/enterprises/addAssociate',
            {
              enterpriseId: this.currentParent,
              associateId: this.selectedRelationEnterprise
            },
            (response) => {
              if (response && response.data && response.data.status === 'success') {
                this.$message.success('关联关系添加成功');
                this.relationDialogVisible = false;
                // 刷新企业列表
                this.fetchSubEnterprises(this.currentParent);
              } else {
                this.$message.error(response.data.message || '关联关系添加失败');
              }
            }
        );
      } catch (error) {
        this.$message.error('关联关系添加失败');
      }
    },
  },
};
</script>

<style scoped>
.enterprise-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.back-button {
  width: 80px;
  margin-bottom: 10px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
}

.current-enterprise-info {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  text-align: center;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 10px;
}

.action-button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  white-space: nowrap; /* 防止按钮文字换行 */
}

.enterprise-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
}

.enterprise-name {
  flex: 1;
  white-space: normal;
  word-wrap: break-word;
}

.arrow {
  margin-left: 10px;
  font-size: 18px;
  color: #666;
}
/* 新增对话框样式 */
.el-select {
  width: 100%;
}
</style>